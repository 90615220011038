@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;500&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

.snowflakes {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.snowflake {
    position: absolute;
    top: -50px;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    opacity: 0.8;
    box-shadow: 0 0 10px white;
}

.container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.left-side {
    width: 50%;
    background-color: rgba(0, 123, 255, 0.8); /* Blue background with opacity */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.left-side:hover {
    background-color: rgba(0, 86, 179, 0.8);
}

.profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.left-side h1 {
    margin-top: 20px;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
    transition: color 0.3s ease-in-out;
}

.left-side:hover .profile-pic {
    transform: scale(1.1);
}

.left-side:hover h1 {
    color: #ffeb3b;
}

.right-side {
    width: 50%;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(244, 244, 244, 0.8);
}

.content {
    max-width: 600px;
    margin: auto;
    animation: fadeIn 1s ease-in-out;
}

section {
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.6s forwards;
}

section:nth-child(1) {
    animation-delay: 0.2s;
}

section:nth-child(2) {
    animation-delay: 0.4s;
}

section:nth-child(3) {
    animation-delay: 0.6s;
}

section:nth-child(4) {
    animation-delay: 0.8s;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #007BFF;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

p {
    line-height: 1.6;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .left-side, .right-side {
        width: 100%;
        height: 50vh;
    }

    .right-side {
        overflow-y: auto;
    }

    .profile-pic {
        width: 100px;
        height: 100px;
    }

    .left-side h1 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .left-side, .right-side {
        height: auto;
    }

    .left-side {
        padding: 10px;
    }

    .right-side {
        padding: 10px;
    }

    .profile-pic {
        width: 80px;
        height: 80px;
    }

    .left-side h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.2em;
    }

    p {
        font-size: 0.9em;
    }
}
